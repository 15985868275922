<mat-expansion-panel [expanded]="expanded" >
    <mat-expansion-panel-header>
        <mat-icon svgIcon="account-box"></mat-icon>
        <span class="headerText"> Popular recipients </span>
    </mat-expansion-panel-header>

    <mat-nav-list dense>
        <ng-content></ng-content>

        <mat-list-item *ngFor="let recipient of recipients"
            (click)="recipientClicked.emit(recipient.address)"
        >
            {{ recipient.name }} ({{ recipient.address }})
        </mat-list-item>
    </mat-nav-list>
</mat-expansion-panel>
