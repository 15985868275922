<section class="mat-typography">
    <h1> Payment History </h1>

    <div *ngIf="transactions | async as transactions; else loading">

        <p>
            Here you can see a list of all your payment transactions.
        </p>
	<p>
	    Click "Show receipt" to view more details about each payment.
	</p>

        <table mat-table [dataSource]="transactions" [ngStyle]="{ width: '100%' }" class="paymentsTable" multiTemplateDataRows>
            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let t" style="font-weight: bold"> {{ t.time.format('YYYY-MM-DD') }} </td>
            </ng-container>
            <ng-container matColumnDef="time-desc">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let t"> ({{ t.time.fromNow() }}) </td>
            </ng-container>
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef> Amount </th>
                <td mat-cell *matCellDef="let t"> {{ t.amount }} {{ t.currency }} </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let t"> {{ statuses[t.status] }} </td>
            </ng-container>
            <ng-container matColumnDef="method">
                <th mat-header-cell *matHeaderCellDef> Method </th>
                <td mat-cell *matCellDef="let t"> {{ methods[t.method] }} </td>
            </ng-container>
            <ng-container matColumnDef="receipt">
                <th mat-header-cell *matHeaderCellDef> Receipt </th>
                <td mat-cell *matCellDef="let t">
                    <button mat-raised-button routerLink="/account/receipt/{{ t.tid }}" color="primary"> Show receipt </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="expansionIndicator">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let t">
                    <mat-icon *ngIf="t == expandedTransaction; else notExpanded" svgIcon="chevron-down"></mat-icon>
                    <ng-template #notExpanded>
                        <mat-icon svgIcon="chevron-right"></mat-icon>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="statusIcon">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let t">
                    <mat-icon *ngIf="t.status === 0" svgIcon="check"></mat-icon>
                    <mat-icon *ngIf="t.status === 1" svgIcon="timer-sand"></mat-icon>
                    <mat-icon *ngIf="t.status === 2" svgIcon="credit-card-refund"></mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetails">
                <td mat-cell *matCellDef="let t" [attr.colspan]="displayedColumns.length">
                    <div class="expandedDetails" [@detailExpand]="t == expandedTransaction ? 'expanded' : 'collapsed'">
                        <!-- yo dawg, I heard you like tables -->
                        <table class="detailsTable">
                            <tr>
                                <td> Status </td>
                                <td> {{ statuses[t.status]}} </td>
                            </tr>
                            <tr>
                                <td> Method </td>
                                <td> {{ methods[t.method]}} </td>
                            </tr>
                            <tr>
                                <td> Receipt </td>
                                <td>
                                    <button mat-button class="contentButton" routerLink="/account/receipt/{{ t.tid }}" class="contentButton">
                                    Show receipt </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"
                [ngStyle]="{ 'display': mobileQuery.matches ? 'none' : null }">
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="regularRow" (click)="rowClicked(row)">
            </tr>

            <tr mat-row *matRowDef="let row; columns: ['expandedDetails']" class="detailsRow" style="height: 0px"></tr>
        </table>

    </div>

    <ng-template #loading>
        <app-runbox-loading text="Loading your payments...">
        </app-runbox-loading>
    </ng-template>
</section>
