<mat-card class="mat_card mat-elevation-z0 update create modal-card">
    <mat-card-header class="header">
        <mat-card-title class="header-title">
            <span *ngIf="is_create">Create profile</span>
            <span *ngIf="is_update">Edit profile: {{identity.name}}</span>
        </mat-card-title>
        <mat-card-subtitle class="header-subtitle">
            <div *ngIf="identity.type === 'main'">
                Username Identity
            </div>
            <div *ngIf="identity.type === 'aliases'">
                Alias
            </div>
            <div *ngIf="identity.from_priority === 0">
                Default Identity
            </div>
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content class="content" style="margin-bottom: 0;">
        <form>
            <mat-form-field class="form-field form-item">
                <input matInput placeholder="From Name" name="from" [(ngModel)]="identity.from_name"
                    (ngModelChange)="onchange_field('from_name')" />
                <div *ngIf="field_errors && field_errors.from_name">
                    <mat-hint> ie. James Bond </mat-hint>
                    <mat-error *ngFor="let error of field_errors.from_name; let i = index;">
                        {{error}}
                    </mat-error>
                </div>
            </mat-form-field>

            <mat-form-field class="form-field form-item"
                *ngIf="(identity.type === 'aliases' || identity.type === 'main' ) && identity.preferred_runbox_domain && profileService.global_domains ; else other_content">
                <mat-label>Email</mat-label>
                <mat-select [(ngModel)]="identity.preferred_runbox_domain"
                    [(value)]="identity.preferred_runbox_domain"
                    (ngModelChange)="onchange_field('preferred_runbox_domain')" name="preferred_runbox_domain"
                    [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let runbox_domain of profileService.global_domains" [value]="runbox_domain.name">
                        {{localpart}}@{{runbox_domain.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-template #other_content>
                <mat-form-field class="form-field form-item">
                    <input matInput placeholder="Email" [ngStyle]="get_form_field_style()" name="email"
                        [readonly]="( identity && (identity.type === 'aliases' || identity.type === 'main' ))"
                        [(ngModel)]="identity.email" (ngModelChange)="onchange_field('email')" />
                    <div *ngIf="field_errors && field_errors.email">
                        <mat-hint>
                            ie. jamesbond@runbox.com
                        </mat-hint>
                        <mat-error *ngFor="let error of field_errors.email; let i = index;">
                            {{error}}
                        </mat-error>
                    </div>
                </mat-form-field>
            </ng-template>

            <mat-form-field class="form-field form-item">
                <input matInput placeholder="Description" name="name" [(ngModel)]="identity.name"
                    (ngModelChange)="onchange_field('name')" />
                <div *ngIf="field_errors && field_errors.name">
                    <mat-hint> ie. My main identity </mat-hint>
                    <mat-error *ngFor="let error of field_errors.name; let i = index;">
                        {{error}}
                    </mat-error>
                </div>
            </mat-form-field>

            <div class="form-checkbox form-item">
                <mat-checkbox name="is_different_reply_to" (change)="onchange_field('is_different_reply_to')"
                    [(ngModel)]="is_different_reply_to">
                    Use different Reply-to
                </mat-checkbox>
            </div>

            <mat-form-field class="form-field form-item" *ngIf="is_different_reply_to">
                <input matInput placeholder="Reply-to" name="reply_to" [(ngModel)]="identity.reply_to"
                    (ngModelChange)="onchange_field('reply_to')" />
                <div *ngIf="field_errors && field_errors.reply_to">
                    <mat-hint>ie. noreply@runbox.com</mat-hint>
                    <mat-error *ngFor="let error of field_errors.reply_to; let i = index;">
                        {{error}}
                    </mat-error>
                </div>
            </mat-form-field>

            <mat-form-field class="signature form-item">
                <textarea matInput placeholder="Signature" [id]="selector" name="signature"
                    [(ngModel)]="identity.signature" (ngModelChange)="onchange_field('signature')"></textarea>
                <div *ngIf="field_errors && field_errors.signature">
                    <mat-hint>
                        ie.
                        <br />Mr. James Bond
                        <br />---------------
                        <br />"My name is Bond, James Bond"
                    </mat-hint>
                    <mat-error *ngFor="let error of field_errors.signature; let i = index;">
                        {{error}}
                    </mat-error>
                </div>
            </mat-form-field>

            <div class="form-checkbox form-item">
                <mat-checkbox name="is_signature_html" [(ngModel)]="identity.is_signature_html"
                    (change)="toggle_signature_html()">
                    Use HTML for signature
                </mat-checkbox>
            </div>
        </form>
    </mat-card-content>

    <div class="warning-container" *ngIf="identity.reference_type == 'preference' && identity.reference.status === 1">
        Email not validated. Check your email or
        <a href="javascript:void(0)" (click)="resend_validate_email(identity.id)">
            re-send</a>.
    </div>

    <p class="mat-small" style="text-align: center; margin-top: 0;" *ngIf="identity.type === 'aliases'">To manage your aliases, please visit <a routerLink href="/account/aliases">Email Aliases</a>.</p>

    <mat-card-actions class="action-buttons">
        <span class="delete-button">
            <button *ngIf="identity.type !== 'main' && identity.type !== 'aliases' && identity.from_priority !== 0" mat-raised-button (click)="delete()" color="warn">Delete</button>
            <span *ngIf="identity.type === 'main' || identity.type === 'aliases' || identity.from_priority === 0">
                This Identity cannot be deleted.
            </span>
        </span>
        <span>
            <button mat-raised-button (click)="close()" color="accent">Cancel</button>
            <button id="save" mat-raised-button (click)="save()" color="primary">Save</button>
        </span>
    </mat-card-actions>
</mat-card>
