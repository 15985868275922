<app-runbox-container>
    <nav>
        <h3 class="sideNavHeader">Settings Menu</h3>
        <mat-accordion>
            <!-- MAIL -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="email"></mat-icon> <span>Mail</span>
                    </p>
                </mat-expansion-panel-header>
                <mat-list-item routerLink="/account/identities">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="account-group"></mat-icon> <span>Identities</span>
                    </p>
                </mat-list-item>
                <mat-list-item routerLink="/account/aliases">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="account-circle"></mat-icon> <span>Email Aliases</span>
                    </p>
                </mat-list-item>
            </mat-expansion-panel>

            <!-- MANAGER -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="briefcase"></mat-icon> <span>Manager</span>
                    </p>
                </mat-expansion-panel-header>
                <a href="/mail/fetch" target="rmm6">
                    <mat-list-item [matTooltip]="rmm6tooltip">
                        <p mat-line>
                            <mat-icon mat-list-icon svgIcon="cloud-download"></mat-icon> <span>Retrieve</span>
                        </p>
                    </mat-list-item>
                </a>
                <a href="/mail/rules" target="rmm6">
                    <mat-list-item [matTooltip]="rmm6tooltip">
                        <p mat-line>
                            <mat-icon mat-list-icon svgIcon="filter-variant"></mat-icon> <span>Filter</span>
                        </p>
                    </mat-list-item>
                </a>
                <a href="/mail/access" target="rmm6">
                    <mat-list-item [matTooltip]="rmm6tooltip">
                        <p mat-line>
                            <mat-icon mat-list-icon svgIcon="download"></mat-icon> <span>Access</span>
                        </p>
                    </mat-list-item>
                </a>
            </mat-expansion-panel>

            <!-- HOSTING -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="cloud"></mat-icon> <span>Hosting</span>
                    </p>
                </mat-expansion-panel-header>
                <a href="/mail/account_domain" target="rmm6">
                    <mat-list-item [matTooltip]="rmm6tooltip">
                        <p mat-line>
                            <mat-icon mat-list-icon svgIcon="email"></mat-icon> <span>Email Hosting</span>
                        </p>
                    </mat-list-item>
                </a>
                <a href="/mail/account_domain_hosting" target="rmm6">
                    <mat-list-item [matTooltip]="rmm6tooltip">
                        <p mat-line>
                            <mat-icon mat-list-icon svgIcon="domain"></mat-icon> <span>Domain Hosting</span>
                        </p>
                    </mat-list-item>
                </a>
                <a href="/mail/account_webhosting" target="rmm6">
                    <mat-list-item [matTooltip]="rmm6tooltip">
                        <p mat-line>
                            <mat-icon mat-list-icon svgIcon="earth"></mat-icon> <span>Web Hosting</span>
                        </p>
                    </mat-list-item>
                </a>
            </mat-expansion-panel>

            <!-- ACCOUNT -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="account-cog"></mat-icon> <span>Account</span>
                    </p>
                </mat-expansion-panel-header>
                <mat-list-item routerLink="/account/details">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="face"></mat-icon> <span>Personal Details</span>
                    </p>
                </mat-list-item>
                <mat-list-item routerLink="/account/storage">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="view-list"></mat-icon> <span>Storage and Data</span>
                    </p>
                </mat-list-item>
                <mat-list-item routerLink="/account/preferences">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="cog"></mat-icon> <span>Preferences</span>
                    </p>
                </mat-list-item>
                <a href="/mail/account_account" target="rmm6" *ngIf="isMainAccount">
                    <mat-list-item [matTooltip]="rmm6tooltip">
                        <p mat-line>
                            <mat-icon mat-list-icon svgIcon="account-supervisor"></mat-icon> <span>Sub-Accounts</span>
                        </p>
                    </mat-list-item>
                </a>
            </mat-expansion-panel>

            <!-- SECURITY -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="security"></mat-icon> <span>Security</span>
                    </p>
                </mat-expansion-panel-header>
                <mat-list-item routerLink="/account/account_password">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="form-textbox-password"></mat-icon> <span>Account
                            Password</span>
                    </p>
                </mat-list-item>
                <mat-list-item routerLink="/account/2fa">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="cellphone-lock"></mat-icon> <span>Two-Factor
                            Authentication</span>
                    </p>
                </mat-list-item>
                <mat-list-item routerLink="/account/app_passwords">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="lock-plus"></mat-icon> <span>App Passwords</span>
                    </p>
                </mat-list-item>
                <mat-list-item routerLink="/account/manage_services">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="tools"></mat-icon> <span>Manage Services</span>
                    </p>
                </mat-list-item>
                <!-- <mat-list-item routerLink="/account/last_logins">
                        <p mat-line>
                            <mat-icon mat-list-icon svgIcon="login-variant"></mat-icon> <span>Last Logins</span>
                        </p>
                    </mat-list-item> -->
                <mat-list-item routerLink="/account/sessions">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="history"></mat-icon> <span>Sessions</span>
                    </p>
                </mat-list-item>
            </mat-expansion-panel>

            <!-- SUBSCRIPTIONS -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="credit-card-check"></mat-icon> <span>Subscriptions</span>
                    </p>
                </mat-expansion-panel-header>
                <mat-list-item routerLink="/account/subscriptions">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="folder-star-multiple"></mat-icon> <span>Your
                            Subscriptions</span>
                    </p>
                </mat-list-item>
                <mat-list-item routerLink="/account/plans">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="card-account-details"></mat-icon> <span>Plans &
                            Upgrades</span>
                    </p>
                </mat-list-item>
                <mat-list-item routerLink="/account/payments">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="receipt"></mat-icon> <span>Payment History</span>
                    </p>
                </mat-list-item>
                <mat-list-item routerLink="/account/payment_cards">
                    <p mat-line>
                        <mat-icon mat-list-icon svgIcon="credit-card"></mat-icon> <span>Payment Cards</span>
                    </p>
                </mat-list-item>
            </mat-expansion-panel>
        </mat-accordion>
    </nav>

    <ng-container toolbar *ngIf="mobileQuery.matches && cart.items | async as items">
        <div style="width: 100%; display: flex; justify-content: right">
            <button mat-mini-fab *ngIf="items.length > 0" routerLink="/account/cart">
                <mat-icon [matBadge]="items.length" matBadgePosition="below before" svgIcon="cart">
                </mat-icon>
            </button>
        </div>
    </ng-container>

    <div *ngIf="!mobileQuery.matches && cart.items | async as items">
        <button mat-fab id="shoppingCartButton" *ngIf="items.length > 0" matTooltip="Go to your shopping cart"
            routerLink="/account/cart">
            <mat-icon [matBadge]="items.length" matBadgePosition="below before" svgIcon="cart">
            </mat-icon>
        </button>
    </div>

    <div style="padding-left: 25px; padding-right: 25px; padding-top: 10px">
        <router-outlet>
        </router-outlet>
    </div>
</app-runbox-container>
