<mat-form-field style="width: 100%" floatPlaceholder="auto">
    <mat-chip-list #chipList>
        <mat-chip *ngFor="let recipient of recipientsList; let ndx=index" [selectable]="selectable"
                style="background-color: #e3f2fd;"
                [removable]="true" 
                (removed)="removeRecipient(ndx)">        
                {{recipient.nameAndAddress}}
                <mat-icon matChipRemove svgIcon="close"></mat-icon>
        </mat-chip>
        <input #searchTextInput [formControl]="searchTextFormControl" [placeholder]="placeholder" 
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"                
                [matChipInputAddOnBlur]="false"
                (blur)="addRecipientFromBlur()"
                (matChipInputTokenEnd)="addRecipientFromEnter($event)" 
                [matAutocomplete]="auto" style="flex-grow: 1" 
                type="email"
            />
        <mat-error *ngIf="invalidemail">
            Please enter a valid email address
        </mat-error>        
    </mat-chip-list>
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="addRecipientFromAutoComplete($event.option.value)">
    <mat-option *ngFor="let recipient of filteredRecipients | async" [value]="recipient">
        {{ recipient.name }}
    </mat-option>
</mat-autocomplete>
