<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>{{question}}</div>
<div mat-dialog-actions style="display: flex">
  <p style="flex-grow: 1">

  </p>
  <button mat-icon-button 
    [matTooltip]="yesOptionTitle" (click)="dialogRef.close(true)">
      <mat-icon svgIcon="check"></mat-icon>
  </button>
  <button mat-icon-button 
    (click)="dialogRef.close(false)" [matTooltip]="noOptionTitle">
    <mat-icon svgIcon="cancel"></mat-icon>
  </button>
</div>
