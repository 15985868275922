<app-runbox-container>
	<section class="mat-typography">
		<h1>Help Center</h1>

		<p>Get help, check our service status, find documentation, or contact our friendly Support Staff!</p>
		<br />

		<div class="gridHighlight">
			<div class="settingsRow">
				<mat-card class="settingsItem">
					<a href="https://help.runbox.com/" target="help">
						<h3>
							<mat-icon svgIcon="help-circle"></mat-icon> <strong>Help Portal</strong>
						</h3>
						<p>Our Help Portal lets you easily find what you are looking for, including how-tos, FAQs, or
							documentation.</p>
						<p>You can also browse all Help topics or search directly for what you're looking for.</p>
					</a>
				</mat-card>

				<mat-card class="settingsItem">
					<a href="https://status.runbox.com/" target="status">
						<h3>
							<mat-icon svgIcon="check-circle"></mat-icon> <strong>Service Status</strong>
						</h3>
						<p>Check the status of our email service, in case there are disruptions we already are aware of.
						</p>
						<p>This is a good place to start if you are unable to connect to our servers.</p>
					</a>
				</mat-card>

				<mat-card class="settingsItem">
					<a href="https://help.runbox.com/how-tos/" target="help">
						<h3>
							<mat-icon svgIcon="format-list-numbered"></mat-icon> <strong>How-Tos</strong>
						</h3>
						<p>Here you will find detailed guides on common Runbox tasks such as setting up email clients
							and domains.</p>
						<p>Many of our how-tos include screenshots and include step-by-step instructions.</p>
					</a>
				</mat-card>

				<mat-card class="settingsItem">
					<a href="https://help.runbox.com/faq/" target="help">
						<h3>
							<mat-icon svgIcon="lightbulb-outline"></mat-icon> <strong>FAQs</strong>
						</h3>
						<p>The FAQs include answers to many of our customers' frequently asked questions.</p>
						<p>It is therefore not unlikely that your question is already answered here.</p>
					</a>
				</mat-card>

				<mat-card class="settingsItem">
					<a href="https://help.runbox.com/documentation/" target="help">
						<h3>
							<mat-icon svgIcon="format-list-bulleted"></mat-icon> <strong>Documentation</strong>
						</h3>
						<p>Our documentation is well-organized and covers all the sections and features of Runbox.</p>
						<p>You will find information on everything from how to use the Webmail to changing Account
							settings and more.</p>
					</a>
				</mat-card>

				<mat-card class="settingsItem">
					<a href="https://forum.runbox.com/" target="forum">
						<h3>
							<mat-icon svgIcon="forum"></mat-icon> <strong>Forum</strong>
						</h3>
						<p>The Runbox Forum is the place where the Runbox Community comes to discuss everything Runbox.
						</p>
						<p>If you have any questions or suggestions, this is the place to hang out!</p>
					</a>
				</mat-card>

				<mat-card class="settingsItem">
					<a href="https://help.runbox.com/runbox-support/" target="help">
						<h3>
							<mat-icon svgIcon="comment-question"></mat-icon> <strong>Support</strong>
						</h3>
						<p>If you have an account-specific question or one that can't be answered elsewhere, our Support
							Staff is ready to help!</p>
						<p>Note that the Support Center requires separate registration.</p>
					</a>
				</mat-card>

			</div>
		</div>
	</section>
</app-runbox-container>
