<section class="mat-typography">
    <app-runbox-section>
        <div runbox-section-header class="section-header-with-toggle">
            <h1 class="runbox-section-header">Two-Factor Authentication</h1>
            <mat-slide-toggle [(ngModel)]="rmm.account_security.tfa.settings.is_2fa_enabled"
                (ngModelChange)="toggle_2fa()">
                {{ rmm.account_security.tfa.settings.is_2fa_enabled ? 'Enabled' : 'Disabled' }}
            </mat-slide-toggle>
        </div>
        <div runbox-section-content class="runbox-section-content">
            <p>This is the Two-Factor Authentication (2FA) configuration screen, where you can configure the various
                options for enabling and using 2FA.</p>
            <p>We recommended that you create an <strong>Unlock Code</strong> when enabling 2FA. This special code can
                be used to disable 2FA if you have problems logging in with your 2FA codes.</p>
            <p><em><strong>Note:</strong> For security reasons, enabling 2FA will disable your
                    account password for non-web services. These include IMAP, POP, SMTP, FTP, CalDAV, and CardDAV
                    services that may be used with progams or apps on your device. Therefore you will need to set up App
                    Passwords for these services if you want to continue using them.</em></p><br>
            <p>For more information on these settings, please see <a
                    href="https://help.runbox.com/account-security/#Two-Factor_Authentication" target="help">Two-Factor
                    Authentication Help</a>.</p>
        </div>
    </app-runbox-section>
    <app-runbox-section size="h2">
        <div runbox-section-header class="section-header-with-toggle">
            <h2 class="runbox-section-header">Timed One-Time Passwords</h2>
            <mat-slide-toggle [(ngModel)]="rmm.account_security.tfa.settings.is_device_2fa_enabled"
                (ngModelChange)="toggle_totp_device()">
                {{ rmm.account_security.tfa.settings.is_device_2fa_enabled ? 'Enabled' : 'Disabled' }}
            </mat-slide-toggle>
        </div>
        <div runbox-section-content class="runbox-section-content">
            <p></p>
            <p>Timed One-Time Passwords (<strong>TOTP</strong>) are 6 digit codes that change every 30 seconds. The
                codes
                are generated by a free app that you download onto your smartphone or computer.</p>
            <p>To generate codes for your account the system will provide you with a secret key. The secret key is
                entered
                into the app, and the generated codes along with your username and account password are used to log into
                your account.</p>
            <p></p>
            <div id="totp_instructions">
                <div>
                    <h2>Setting up Timed One-Time Passwords</h2>
                    <p>First you need to download and install one of the recommended <a
                            href="https://help.runbox.com/account-security/#Timed_One-Time_Passwords_TOTP">TOTP
                            apps</a>,
                        unless
                        you already have a TOTP app.</p>
                    <p>Follow the steps below to set up TOTP. The app you installed will help guide you through the
                        process.
                    </p>
                    <p>When you have completed the setup, 2FA will be enabled for your account.</p>
                    <p>The next time you log into Runbox, you will be asked for a TOTP code in addition to your username
                        and
                        account password.</p>
                </div>

                <div id="totp_example">
                    <div>
                        <div style="text-align: center; padding: 5px 0">Example TOTP app</div>
                        <div id="totp-animation">
                            <div style="font-size: 14px;" class="totp-timer timer fill">
                                <div class="percent">50%</div>
                                <div id="slice" class="gt50">
                                    <div style="transform: rotate(180.072deg);" class="pie"></div>
                                    <div style="transform: rotate(180.072deg);" class="pie fill"></div>
                                </div>
                            </div>
                            <div class="totp-timer-code">136929</div>
                        </div>
                        <img class="base64scan">
                    </div>
                    <div>
                        Recommended TOTP apps:
                        <ul class="square-list">
                            <li>Authy (<a href="https://itunes.apple.com/br/app/authy/id494168017?mt=8">iOS</a>/<a
                                    href="https://play.google.com/store/apps/details?id=com.authy.authy">Android</a>)
                            </li>
                            <li>FreeOTP Authenticator (<a
                                    href="https://itunes.apple.com/en/app/freeotp-authenticator/id872559395?mt=8">iOS</a>/<a
                                    href="https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp&amp;hl=en">Android</a>)
                            </li>
                            <li>Google Authenticator (<a
                                    href="https://itunes.apple.com/br/app/google-authenticator/id388497605?mt=8">iOS</a>/<a
                                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Android</a>)
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div style="clear: both"></div>

            <div id="totp-steps">
                <div>
                    <p>
                        1. Click [Generate Key] to generate a new TOTP key.
                    </p>
                    <p>
                        <span><button mat-raised-button class="primaryContentButton"
                                (click)="totp_generate_new_key()">Generate
                                Key</button></span>
                        <mat-form-field>
                            <input matInput name="new_totp_code" placeholder="Key" maxlength="16" disabled=""
                                [value]="rmm.account_security.tfa.new_totp_code">
                        </mat-form-field>
                    </p>
                </div>
                <div>
                    <p>
                        2. Note the label below, as it will identify the key in the TOTP app on your device.
                    </p>
                    <p>
                        <span><strong>Label:</strong></span>
                        <mat-form-field>
                            <input matInput name="totp_label" [value]="rmm.account_security.tfa.totp_label"
                                disabled="" readonly >
                        </mat-form-field>
                    </p>
                </div>
                <div>
                    <p>
                        3. Scan the generated QR code below when requested by the app, or manually enter the key above
                        into
                        the
                        app.
                    </p>
                </div>
                <div *ngIf="rmm.account_security.tfa.qr_code_value">
                    <p>
                        <span>
                            <qr-code [value]="rmm.account_security.tfa.qr_code_value.href" [size]="250" level="H">
                            </qr-code>
                        </span>
                    </p>
                </div>
                <div>
                    <p>
                        4. Enter the code generated by the app and click [Check Code] to ensure that the setup is
                        correct.
                    </p>
                    <p>
                        <span><strong>Test code:</strong></span>
                        <mat-form-field>
                            <input matInput name="totp_test_code" placeholder="Enter code" maxlength="6"
                                [(ngModel)]="totp_test_code" (ngModelChange)="totp_test_code_change()">
                        </mat-form-field>
                        <span><button mat-raised-button class="primaryContentButton"
                                [disabled]="is_btn_totp_check_disabled" (click)="totp_check_code()">Check
                                Code</button></span>
                    </p>
                    <p>
                        5. If the code is correct, Two-Factor Authenetication with TOTP will be enabled for your
                        account.
                    </p>
                </div>
            </div>
        </div>
    </app-runbox-section>
    <app-runbox-section size="h2">
        <div runbox-section-header class="section-header-with-toggle">
            <h2 class="runbox-section-header">One-Time Passwords</h2>
            <mat-slide-toggle [(ngModel)]="rmm.account_security.tfa.settings.is_otp_enabled"
                (ngModelChange)="toggle_otp()">
                {{ rmm.account_security.tfa.settings.is_otp_enabled ? 'Enabled' : 'Disabled' }}
            </mat-slide-toggle>
        </div>
        <div runbox-section-content class="runbox-section-content">
            <p>One-Time Passwords (<strong>OTP</strong>) are codes that can be used only once. The OTP must be used
                together with your username and account password to log in.</p>
            <p>You can generate new codes at any time, and this will invalidate any existing codes. A total of 40 codes
                with 16 characters each are generated each time.</p>
            <p>Click the button below to generate a new list of codes that you can copy &amp; paste or print out.</p>
            <span><button mat-raised-button class="primaryContentButton" (click)="otp_generate()"
                    [disabled]="is_busy_otp_update">Generate Codes</button></span>
            <p></p>
            <div *ngIf="is_busy_otp_update" style="display: flex; justify-content: center">
                <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
            </div>
            <ul
                *ngIf="rmm.account_security.tfa.otp_generated_list && rmm.account_security.tfa.otp_generated_list.passwords">
                <li *ngFor="let password of rmm.account_security.tfa.otp_generated_list.passwords">
                    <span>{{password.value}}</span>
                </li>
            </ul>
        </div>
    </app-runbox-section>
    <app-runbox-section size="h2">
        <div runbox-section-header>
            <h2 class="runbox-section-header">Trusted Browsers</h2>
        </div>
        <div runbox-section-content class="runbox-section-content">
            <p>Trusted browsers are able to log into Runbox with just the username and account password even if 2FA is
                enabled.</p>
            <div style="display: flex; flex-wrap: wrap; align-items: center;">
                <p style="margin-bottom: 0px;">
                    Browser name:
                    <mat-form-field>
                        <input matInput name="trusted_browser_name" placeholder="Enter browser/device name"
                            [(ngModel)]="trusted_browser_name" (ngModelChange)="trusted_browser_name_change()">
                    </mat-form-field>
                </p>
                <div style="display: flex; flex-wrap: wrap-reverse; align-items: center; gap: 20px;">
                    <div><button mat-raised-button class="primaryContentButton"
                            [disabled]="is_btn_trust_browser_disabled" (click)="device_trust_create()">Trust this
                            Browser</button></div>
                    <div>Suggestion: "Firefox on PC"</div>
                </div>
            </div>
            <table mat-table style="width: 100%" *ngIf="rmm.account_security.device.results"
                [dataSource]="rmm.account_security.device.results" multiTemplateDataRows>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let device">
                        {{ device.name }}
                        <mat-chip-list *ngIf="device.is_current_device" style="padding-left: 10px;">
                            <mat-chip>Current device</mat-chip>
                        </mat-chip-list>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef
                        [ngStyle]="{ 'text-align': mobileQuery.matches ? 'end' : null }">
                        Status
                    </th>
                    <td mat-cell *matCellDef="let device"
                        [ngStyle]="{ 'text-align': mobileQuery.matches ? 'end' : null }">
                        <mat-slide-toggle [(ngModel)]="device.is_trusted" (ngModelChange)="device_toggle(device)">
                            {{ device.is_trusted ? 'Trusted' : 'Not trusted' }}
                        </mat-slide-toggle>
                    </td>
                </ng-container>

                <ng-container matColumnDef="created">
                    <th mat-header-cell *matHeaderCellDef> Created </th>
                    <td mat-cell *matCellDef="let device"> {{ device.created }} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let device">
                        <button mat-raised-button class="primaryContentButton"
                            (click)="device_delete(device.id)">Delete</button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="details">
                    <td mat-cell *matCellDef="let device" [attr.colspan]="trusted_browser_columns_mobile.length">
                        <div
                            style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                            <div> Created on <span style="white-space: nowrap;">{{ device.created }}</span> </div>
                            <div>
                                <button mat-raised-button class="primaryContentButton"
                                    (click)="device_delete(device.id)">Delete</button>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="mobileQuery.matches ? trusted_browser_columns_mobile : trusted_browser_columns_desktop">
                </tr>
                <tr mat-row
                    *matRowDef="let row; columns: mobileQuery.matches ? trusted_browser_columns_mobile : trusted_browser_columns_desktop;"
                    [ngClass]="{ 'regularRow': mobileQuery.matches }"></tr>
                <tr mat-row *matRowDef="let row; columns: ['details']" class="detailsRow"
                    [ngStyle]="{ 'display': mobileQuery.matches ? null : 'none' }"></tr>
            </table>
        </div>
    </app-runbox-section>
    <app-runbox-section size="h2">
        <div runbox-section-header>
            <h2 class="runbox-section-header">Unlock Code</h2>
        </div>
        <div runbox-section-content class="runbox-section-content">
            <p>
                An unlock code is required so that if you lose your TOTP or OTP codes, you can still access your
                account.
            </p>
            <p>
                Using the unlock code will turn off 2FA for your account. You can easily reactivate 2FA on this page
                once
                you are successfully logged in again.
            </p>
            <div *ngIf="is_busy_unlock_code_update" style="display: flex; justify-content: center">
                <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
            </div>
            <span><button mat-raised-button class="primaryContentButton" (click)="unlock_code_generate()"
                    [disabled]="is_busy_unlock_code_update">Generate Code</button></span>
            <span *ngIf="this.rmm.account_security.unlock_code.unlock_code">
                {{this.rmm.account_security.unlock_code.unlock_code}}
            </span>
            <p></p>
        </div>
    </app-runbox-section>
</section>
