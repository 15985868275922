<section class="mat-typography">
    <app-runbox-section>
        <div runbox-section-header>
            <h1 class="runbox-section-header">Manage Services</h1>
        </div>
        <div runbox-section-content class="runbox-section-content">
            <p>On this screen you can enable or disable specific services. When a service is disabled it's not possible
                to
                log into that service at all.</p>
            <p>Disabling all the services you don't use adds an extra layer of security to your account, as it
                completely
                prevents attempts to gain unauthorized access via those services.</p>
            <p>For more information on these settings, please see <a
                    href="https://help.runbox.com/account-security/#Manage_Services" target="help">Manage Services
                    Help</a>.
            </p>
            <table mat-table style="width: 100%" *ngIf="rmm.account_security.service.results"
                [dataSource]="service_rows" multiTemplateDataRows>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let service"> {{ service.name }} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef
                        [ngStyle]="{ 'text-align': mobileQuery.matches ? 'end' : null }">
                        Status
                    </th>
                    <td mat-cell *matCellDef="let service"
                        [ngStyle]="{ 'text-align': mobileQuery.matches ? 'end' : null }">
                        <mat-slide-toggle [(ngModel)]="rmm.account_security.service.results[service.key].is_enabled"
                            (ngModelChange)="service_toggle(rmm.account_security.service.results[service.key])">
                            {{
                            rmm.account_security.service.results[service.key] &&
                            rmm.account_security.service.results[service.key].is_enabled
                            ? 'Enabled'
                            : 'Disabled'
                            }}
                        </mat-slide-toggle>
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let service"> {{ service.description }} </td>
                </ng-container>

                <ng-container matColumnDef="description-row">
                    <td mat-cell *matCellDef="let service" [attr.colspan]="trusted_browser_columns_mobile.length">
                        {{ service.description }}
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="mobileQuery.matches ? service_columns_mobile : service_columns_desktop"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: mobileQuery.matches ? service_columns_mobile : service_columns_desktop;"
                    [ngClass]="{ 'regularRow': mobileQuery.matches }"></tr>
                <tr mat-row *matRowDef="let row; columns: ['description-row']" class="detailsRow"
                    [ngStyle]="{ 'display': mobileQuery.matches ? null : 'none' }"></tr>
            </table>
        </div>
    </app-runbox-section>
</section>
