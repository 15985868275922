<div class="profile-lister">
    <ng-content select="[section-header]"></ng-content>
    <ng-content select="[section-description]"></ng-content>
    <ng-content select="[section-buttons]"></ng-content>
    <mat-card class="mat-elevation-z2 profile-card" *ngFor="let item of profiles; let i = index;">
        <mat-card-content class="profile-content">
            <mat-grid-list cols="{{mobile?6:7}}" rowHeight="15px">
                <mat-grid-tile *ngIf="item.type === 'main'" colspan="6" rowspan="2">
                    <h3>Username Identity</h3>
                </mat-grid-tile>
                <mat-grid-tile colspan="2" rowspan="2">
                    <span class="float-right">Email:</span>
                </mat-grid-tile>
                <mat-grid-tile colspan="4" rowspan="2">
                    <span class="float-left">{{item.email}}</span>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="8" *ngIf="!mobile">
                    <button mat-raised-button (click)="edit(item)" color="primary">Edit</button>
                </mat-grid-tile>
                <mat-grid-tile colspan="2" rowspan="2">
                    <span class="float-right">From Name:</span>
                </mat-grid-tile>
                <mat-grid-tile colspan="4" rowspan="2">
                    <span class="float-left">{{item.from_name}}</span>
                </mat-grid-tile>
                <mat-grid-tile *ngIf="item.reference.folder" colspan="2" rowspan="2">
                    <span class="float-right">Origin:</span>
                </mat-grid-tile>
                <mat-grid-tile *ngIf="item.reference.folder" colspan="4" rowspan="2">
                    <span class="float-left">{{ 'Runbox 6 Folder: ' + item.reference.folder}}</span>
                </mat-grid-tile>
                <mat-grid-tile colspan="2" rowspan="2">
                    <span class="float-right">Reply To:</span>
                </mat-grid-tile>
                <mat-grid-tile colspan="4" rowspan="2">
                    <span class="float-left">{{item.reply_to}}</span>
                </mat-grid-tile>
                <mat-grid-tile colspan="2" rowspan="2">
                    <span class="float-right">Signature:</span>
                </mat-grid-tile>
                <mat-grid-tile colspan="4" rowspan="2">
                    <span class="float-left">{{item.signature}}</span>
                </mat-grid-tile>
                <mat-grid-tile colspan="{{mobile?6:7}}" rowspan="1"
                    *ngIf="item.reference_type === 'preference' && item.reference.status === 1">
                    <span class="warning">Email not validated.</span>
                </mat-grid-tile>
                <mat-grid-tile colspan="{{mobile?6:7}}" rowspan="1" *ngIf="mobile">
                    <mat-divider></mat-divider>
                </mat-grid-tile>
                <mat-grid-tile colspan="{{mobile?6:7}}" rowspan="3" *ngIf="mobile">
                    <button mat-raised-button (click)="edit(item)" color="primary">Edit</button>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-content>
    </mat-card>
</div>
