<div class="profiles-default">
    <ng-content select="[section-description]"></ng-content>

    <div class="profiles-select">
        <mat-form-field id="default-identity-form">
            <mat-select [value]="selectedProfile" [(ngModel)]="selectedProfile">
                <mat-option class="identity-profile" *ngFor="let profile of validProfiles" [value]="profile">
                    {{profile.nameAndAddress}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="field_errors?.from_priorities">
                {{field_errors.from_priorities[0]}}
            </mat-error>
        </mat-form-field>

        <button type="submit" (click)="updateDefaultProfile()" mat-raised-button color="primary">
            Confirm
        </button>
    </div>
</div>
