<h1 mat-dialog-title>App update available!</h1>
<mat-dialog-content>
  <p>
    An update of the application is available, and you may reload the app now to use the latest version.
  </p>
  <p>
    The new version is {{ data.available.appData.commit || data.available.hash }},
    <span *ngIf="data.available.appData.build_time; let build_time">
      built on {{ build_time }},
    </span> <br>
    and the changes can be seen in the Runbox 7 changelog
    <a href="/app/changelog?since={{ data.current.appData.build_epoch }}">here</a>.<br />
    To provide feedback on Runbox 7 features, please visit our <a href="https://community.runbox.com/c/runbox-7/runbox-7-webmail/10" target="forum">community forum</a>.
  </p>
</mat-dialog-content>
<mat-dialog-actions style="display: flex">
    <span style="flex-grow: 1"></span>
    <button mat-button (click)="reload()">Reload now</button>
    <button mat-button (click)="close()">Later</button>
</mat-dialog-actions>
