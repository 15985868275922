<div id="folderListHeader" style="display: flex">
  <span mat-subheader style="flex-grow: 1">Folders</span>
  <button mat-icon-button id="createFolderButton" matTooltip="Create folder..." (click)="addFolder()">
    <mat-icon svgIcon="plus"></mat-icon>
  </button>
</div>
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" [matTreeNodePadding]="node.folderLevel"
      matTreeNodePaddingIndent="20"
      style="display: flex"
      class="mailFolder"
      [ngClass]="{
        'selectedFolder' : selectedFolder == node.folderPath,
        'dropToFolderAllowed' : dropFolderId===node.folderId && dropAboveOrBelowOrInside === dropPosition.INSIDE,
        'dropAboveFolderAllowed' : dropFolderId===node.folderId && dropAboveOrBelowOrInside === dropPosition.ABOVE,
        'dropBelowFolderAllowed' : dropFolderId===node.folderId && dropAboveOrBelowOrInside === dropPosition.BELOW
        }"
        (drop)="dropToFolder($event,node.folderId)"         
        (dragover)="allowDropToFolder($event,node)" 
        (dragend)="dragCancel()" 
        (click)="selectFolder(node.folderPath)"
      >
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.folderName"
                [disabled]="!node.isExpandable"
                >
          <mat-icon class="mat-icon-rtl-mirror" *ngIf="node.isExpandable" svgIcon="{{treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'}}">
          </mat-icon>
        </button>
        <mat-icon *ngIf="node.folderType=='inbox'" mat-list-icon class="folderIconStandard" svgIcon="inbox-arrow-down"></mat-icon>
        <mat-icon *ngIf="node.folderType=='sent'" mat-list-icon class="folderIconStandard" svgIcon="send"></mat-icon>
        <mat-icon *ngIf="node.folderType=='spam'" mat-list-icon class="folderIconStandard" svgIcon="cancel"></mat-icon>
        <mat-icon *ngIf="node.folderType=='templates'" mat-list-icon class="folderIconStandard" svgIcon="file-document"></mat-icon>
        <mat-icon *ngIf="node.folderType=='trash'" mat-list-icon class="folderIconStandard" svgIcon="delete"></mat-icon>
        <mat-icon *ngIf="node.folderType=='user'" mat-list-icon class="folderIconUser" svgIcon="folder"></mat-icon>
        <div style="margin-left: 5px;" 
              draggable="true"
              (dragstart)="dragFolderStart($event, node.folderId)">
              {{node.folderName}}
        </div>
        <span *ngIf="folderMessageCounts | async as messageCounts">
          <span *ngIf="messageCounts[node.folderPath] !== undefined">
            <span
                *ngIf="messageCounts[node.folderPath].unread > 0"
                [matBadge]="messageCounts[node.folderPath].unread"
                matBadgeOverlap="true" class="newMessagesCount" matBadgeSize="medium">
                &nbsp;
            </span>
          </span>
         
        </span>
	    <span style="flex-grow: 1"></span>
        <span *ngIf="folderMessageCounts | async as messageCounts">
          <span *ngIf="messageCounts[node.folderPath] !== undefined; else NoTotalCountYet">
            <span class="foldersidebarcount">{{ messageCounts[node.folderPath].total }}</span>
          </span>
          <ng-template #NoTotalCountYet>
            <span class="foldersidebarcount">N/A</span>
          </ng-template>
        </span>
        
        <ng-container [ngSwitch]="node.folderType">
          <ng-container *ngSwitchCase="'user'">
            <mat-menu #folderActionsMenu="matMenu">
              <button mat-menu-item (click)="renameFolderDialog(node)">
                <mat-icon  svgIcon="pencil" color="primary"></mat-icon>
                <span>Rename</span>
              </button>
              <button mat-menu-item (click)="addSubFolderDialog(node)">
                <mat-icon  svgIcon="folder-plus" color="primary"></mat-icon>
                <span>New Subfolder</span>
              </button>
              <button mat-menu-item (click)="deleteFolderDialog(node)">
                <mat-icon  svgIcon="delete" color="primary"></mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
            <button mat-icon-button
              (click)="$event.stopPropagation()"
              [matMenuTriggerFor]="folderActionsMenu" matTooltip="Folder actions">
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>              
          </ng-container>  
          <ng-container *ngSwitchCase="'trash'">
            <mat-menu #trashFolderActionsMenu="matMenu">
              <button mat-menu-item (click)="emptyTrash.next(node)">
                <mat-icon svgIcon="notification-clear-all"></mat-icon>
                <span>Empty trash</span>              
              </button>
            </mat-menu>
            <button mat-icon-button
              (click)="$event.stopPropagation()"
              [matMenuTriggerFor]="trashFolderActionsMenu" matTooltip="Folder actions">
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>          
          </ng-container>
          <ng-container *ngSwitchCase="'spam'">
            <mat-menu #trashFolderActionsMenu="matMenu">
              <button mat-menu-item (click)="emptySpam.next(node.folderName)">
                <mat-icon svgIcon="notification-clear-all"></mat-icon>
                <span>Move all to trash</span>              
              </button>
            </mat-menu>
            <button mat-icon-button
              (click)="$event.stopPropagation()"
              [matMenuTriggerFor]="trashFolderActionsMenu" matTooltip="Folder actions">
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>          
          </ng-container>
          <ng-container *ngSwitchDefault>
            <button disabled mat-icon-button *ngIf="node.folderType!=='user'"></button>      
          </ng-container>          
        </ng-container>
    </mat-tree-node>
</mat-tree>
<mat-divider></mat-divider>
