<section class="mat-typography">
    <h1>Identities</h1>
    <div>
        <app-profiles-lister *ngIf="profileService.composeProfile"
            [profiles]="[profileService.composeProfile]">
            <div section-header>
                <h2>Default Identity</h2>
            </div>
            <div section-description>
                <p>Your Default Identity is the address that is used by default on the Compose screen. You may select
                    any other identity as your Default Identity in the drop-down menu below.</p>
            </div>
        </app-profiles-lister>

        <app-profiles-default *ngIf="profileService.validProfiles.value.length > 0"
            [validProfiles] = profileService.validProfiles.value
            [selectedProfile] = profileService.composeProfile
            >
            <div section-description>
                <p style="margin-bottom: 0;">Select the email you want to use as your Default Identity:</p>
            </div>
        </app-profiles-default>
        <br>

        <app-profiles-lister
            *ngIf="profileService.aliases.value.length > 0"
            [profiles]="profileService.aliases.value">
            <div section-header>
                <h2>Identities for Aliases</h2>
            </div>
            <div section-description>
              <p>Aliases are extra email addresses that deliver to your account, just as your main username/email address does. Below are identities you can use if you want to send messages from your aliases.</p>
              <p>These identities can be customised by adding a different From Name, Signature or Reply-to address. You can also change the Runbox domain an alias uses.</p>
              <p>You can't delete these identities as they are tied to your aliases.</p>
                <p style="color: crimson" *ngIf="alias_limits?.current >= alias_limits?.total">
                    You have reached the maximum allowed number of Runbox aliases.
                </p>
              <p>To manage your aliases, please visit <a routerLink="../aliases">Email Aliases</a>.</p>
            </div>
        </app-profiles-lister>
        <br><br>

        <app-profiles-lister
            [profiles]="profileService.otherProfiles.value">
            <div section-header>
                <h2>Other identities</h2>
            </div>
            <div section-description>
                <p>Other Identities can be used when you want to send from addresses that are not part of your Runbox  account. Adding this kind of address (e.g. a work email address) will require verification via  email that you have access to that account. You can also use Other Identities if you need additional identities for you Runbox email addresses.</p>
                <p>Adding new identities require verification via an email sent to the given address.</p>
            </div>
            <div section-buttons>
                <button id="add-identity" mat-raised-button color='primary' style="margin: 10px;" (click)='add_profile()'>
                    Add Identity
                </button>
                {{profileService.otherProfiles.value.length}} identities created
            </div>
        </app-profiles-lister>
    </div>
</section>
