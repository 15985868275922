<style>
    .payment-row {
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 5px;
        margin: 0 5px 10px;
    }

    .payment-field {
        position: relative;
        height: 50px;
        padding: 5px;
        text-align: center;
    }

    @media only screen and (max-width: 767px) {
        .payment-field {
            font-size: 14px;
        }
    }

    .payment-field-big {
        width: 100%;
    }

    .payment-field-small {
        flex: 1 0 25%;
    }
</style>

<h1 mat-dialog-title>Card payment</h1>
    <div style="width: 95%; text-align: center; margin: auto;">
        <img src="/_img/pay/stripe-logo.png" alt="VISA, MasterCard, AmEx" style="width: inherit; height: auto" />
    </div>
    <div [style.display]="state === 'initial' ? 'block' : 'none'">
        <div id="payment-button-row" class="payment-row" [style.display]="paymentRequestsSupported ? 'block' : 'none'">
            <div class="payment-field payment-field-big">
                <div #paymentRequestButton></div>
            </div>
        </div>
        <div class="payment-row" autofocus>
            <div class="payment-field payment-field-big">
                <label for="cardNumber">Card number</label>
                <div #cardNumber id="cardNumber"></div>
            </div>
        </div>
        <div class="payment-row">
            <div class="payment-field payment-field-small">
                <label for="cardExpiry">Expiration date</label>
                <div #cardExpiry></div>
            </div>
            <div class="payment-field payment-field-small">
                <label for="cardCvc">CVC code</label>
                <div #cardCvc></div>
            </div>
            <div class="payment-field payment-field-small StripeElement">
                <mat-form-field>
                    <input matInput [(ngModel)]="zipCode" placeholder="ZIP/postal code">
                </mat-form-field>
            </div>
        </div>

        <div id="card-errors" role="alert" style="display: flex; justify-content: center;">
            <strong> {{ stripeError }} </strong>
        </div>
    </div>

    <div [style.display]="state === 'loading' ? 'block' : 'none'">
        <div> Loading Stripe payment form... </div>
        <mat-spinner style="margin:0 auto;"></mat-spinner>
    </div>

    <div [style.display]="state === 'processing' ? 'block' : 'none'">
        <div> Processing your payment... </div>
        <mat-spinner style="margin:0 auto;"></mat-spinner>
    </div>

    <div [style.display]="state === 'failure' ? 'block' : 'none'">
        <div> {{ stripeError }} </div>
    </div>

    <div [style.display]="state === 'finished' ? 'block' : 'none'">
        <div> Your payment was successful and your products have been activated. </div>
    </div>

    <div class="description" style="text-align: center"> Payment form not working? <a href="/payment/payment?tid={{tid}}&method=stripe" target="_blank">Try our alternative payment system instead.</a> </div>

    <div mat-dialog-actions style="justify-content: center;" *ngIf="state === 'loading'">
      <button mat-button (click)="close()"> Cancel </button>
    </div>
    <div mat-dialog-actions style="justify-content: space-between;" *ngIf="state === 'initial'">
      <button mat-button (click)="close()"> Cancel </button>
      <button mat-flat-button color="primary" (click)="submitPayment()">
        <span *ngIf="state === 'initial'"> Submit payment </span>
      </button>
    </div>
    <div mat-dialog-actions style="justify-content: center;" *ngIf="state === 'failure'">
      <button mat-button (click)="close()"> Close </button>
    </div>
    <div mat-dialog-actions style="justify-content: space-between;" *ngIf="state === 'finished'">
      <button mat-button (click)="close()"> Close </button>
      <button mat-flat-button color="primary" (click)="showReceipt()"> Show Receipt </button>
    </div>
