<div id="startdesk">
  <div id="dashdeskOverviewContainer communicationOverview">
      <div class="highlights">
        <h3><mat-icon>blur_on</mat-icon>Overview</h3>
        <div id="hilightsSettings">
          <mat-form-field>
            <mat-label> Time span </mat-label>
            <mat-select [(ngModel)]="timeSpan" (selectionChange)="updateCommsOverview()">
              <mat-option [value]="TimeSpan.TODAY"> Today </mat-option>
              <mat-option [value]="TimeSpan.YESTERDAY"> Yesterday </mat-option>
              <mat-option [value]="TimeSpan.LASTWEEK"> Last week </mat-option>
              <mat-option [value]="TimeSpan.LASTMONTH"> Last month </mat-option>
              <mat-option [value]="TimeSpan.LASTYEAR"> Last year </mat-option>
              <mat-option [value]="TimeSpan.CUSTOM"> Custom (NYI) </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label> Folder </mat-label>
            <mat-select [(ngModel)]="folder" (selectionChange)="updateCommsOverview()">
              <mat-option [value]="FolderSelection.INBOX">  Inbox only </mat-option>
              <mat-option [value]="FolderSelection.ALL">    All folders </mat-option>
              <mat-option [value]="FolderSelection.CUSTOM"> Custom selection </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label> Sort by </mat-label>
            <mat-select [(ngModel)]="sortOrder" (selectionChange)="updateCommsOverview()">
              <mat-option [value]="SortOrder.COUNT"> Count </mat-option>
              <!--
              <mat-option> Date </mat-option>
              <mat-option> Folder </mat-option>
              -->
              <mat-option [value]="SortOrder.SENDER"> Sender </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-checkbox [(ngModel)]="unreadOnly" (change)="updateCommsOverview()"> Unread only </mat-checkbox>
        </div>
      </div>
      <div id="folderSelector" *ngIf="folder === FolderSelection.CUSTOM">
          <mat-checkbox
              *ngFor="let folder of folderSelectorSwitches"
              [checked]="folder.shown"
              (change)="toggleFolderVisibility(folder.name, $event)"
         > {{ folder.name }} ({{ folder.count }}) </mat-checkbox>
      </div>
      <mat-tab-group>
        <mat-tab label="All emails ({{ totalEmailCount }})">
          <div *ngIf="totalEmailCount === 0" class="noMessagesCard">
            No messages (yet?)
          </div>
          <app-overview-sender-hilights
            *ngFor="let sender of regularOverview.concat(mailingListOverview)"
            [sender]="sender"
            (showMore)="showMoreFor(sender)"
            (showLess)="showLessFor(sender)"
          ></app-overview-sender-hilights>
        </mat-tab>
        <mat-tab label="Directly to you ({{ regularEmailCount }})">
          <div *ngIf="regularEmailCount === 0" class="noMessagesCard">
            No messages (yet?)
          </div>
          <app-overview-sender-hilights
            *ngFor="let sender of regularOverview"
            [sender]="sender"
            (showMore)="showMoreFor(sender)"
            (showLess)="showLessFor(sender)"
          ></app-overview-sender-hilights>
        </mat-tab>
        <mat-tab label="From mailing lists ({{ mailingListEmailCount }})">
          <div *ngIf="mailingListEmailCount === 0" class="noMessagesCard">
            No messages (yet?)
          </div>
          <app-overview-sender-hilights
            *ngFor="let sender of mailingListOverview"
            [sender]="sender"
            (showMore)="showMoreFor(sender)"
            (showLess)="showLessFor(sender)"
          ></app-overview-sender-hilights>
        </mat-tab>
      </mat-tab-group>
  </div>
  
  <!-- <div id="dashdeskSections">
    <mat-card class="mat-card">
      <h3><mat-icon>email</mat-icon>Email highlights</h3>
      <div>New messages: <u>Inbox</u>, <u>Friends</u>, <u>Enemies</u> <mat-icon class="start_chart" style="color: red">show_chart</mat-icon></div>
      <div>New replies: <u>Inbox</u> <mat-icon class="start_chart" style="margin-left: 30px; -webkit-transform: scaleX(-1); transform: scaleX(-1);">show_chart</mat-icon></div>
      <div>Important messages: <u>Gandalf</u>, <u>Bilbo</u> <mat-icon class="start_chart" style="color: red">show_chart</mat-icon></div>
    </mat-card>
    
    <mat-card class="mat-card">
      <h3><mat-icon>people</mat-icon>Contact highlights</h3>
      <div>Email from new senders: <u>Saruman</u> <mat-icon class="start_chart" style="margin-left: 30px; -webkit-transform: scaleX(-1); transform: scaleX(-1);">show_chart</mat-icon></div>
      <div>Email from known contacts: <u>Gandalf</u> <mat-icon class="start_chart" style="color: red">show_chart</mat-icon></div>
      <div>New contacts (received vCards): <u>Gollum</u> <mat-icon class="start_chart" style="color: red">show_chart</mat-icon></div>
    </mat-card>
    
    <mat-card class="mat-card">
      <h3><mat-icon>date_range</mat-icon>Calendar highlights</h3>
      <div>New events: <u>Destroy Ring</u> <mat-icon class="start_chart" style="color: red">timeline</mat-icon></div>
      <div>Today: <u>Have 2nd Breakfast</u> <mat-icon class="start_chart">linear_scale</mat-icon></div>
      <div>This week: <u>Meet at Weathertop</u>, <u>Escape Nazgul</u> <mat-icon class="start_chart" style="color: red">timeline</mat-icon></div>
    </mat-card>
    
    <mat-card class="mat-card">
      <h3><mat-icon>assignment</mat-icon>Task highlights</h3>
      <div>Due tasks: <u>Form Fellowship</u> <mat-icon class="start_chart">insert_chart</mat-icon></div>
      <div>In progress: <u>Hang with Elves</u>, <u>Evade Sauron</u> <mat-icon class="start_chart" style="margin-left: 30px; -webkit-transform: scaleX(-1); transform: scaleX(-1); color: red">insert_chart</mat-icon></div>
      <div>Upcoming: <u>Destroy One Ring</u> <mat-icon class="start_chart" style="margin-left: 30px; -webkit-transform: scaleX(-1); transform: scaleX(-1); color: red">insert_chart</mat-icon></div>
    </mat-card>
  </div> -->
</div>
