<section class="mat-typography">
    <div class="container">
        <h1>Data Usage</h1>
        <table mat-table *ngIf="dataUsage | async as dataUsage" [dataSource]="dataUsage">
            <!-- Type Column -->
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td class="capitalize" mat-cell *matCellDef="let data"> {{ data.name }} </td>
            </ng-container>

            <!-- Quota Column -->
            <ng-container matColumnDef="quota">
                <th mat-header-cell *matHeaderCellDef> Quota </th>
                <td mat-cell *matCellDef="let data">
                    <span *ngIf="data.type === 'absolute'">
                        {{ data.quota }} </span>
                    <span *ngIf="data.type === 'bytes'">
                        {{ (((data.quota)/1024)/1024)/1024 }} GB </span>
                </td>
            </ng-container>

            <!-- Usage Column -->
            <ng-container matColumnDef="usage">
                <th mat-header-cell *matHeaderCellDef> Usage </th>
                <td mat-cell *matCellDef="let data">
                    <span *ngIf="data.type === 'absolute'">
                        {{ data.usage ? data.usage : 0 }} </span>
                    <span *ngIf="data.type === 'bytes'">
                        {{ ((((data.usage ? data.usage : 0)/1024)/1024)/1024).toFixed(2) }} GB</span> </td>
            </ng-container>

            <!-- % used Column -->
            <ng-container matColumnDef="percentage_used">
                <th mat-header-cell *matHeaderCellDef> % used </th>
                <td mat-cell *matCellDef="let data"> {{ data.percentage_used.toFixed(2) }} % </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <!-- <div class="container">
        <h2>Storage Details</h2>
        <p>Location: //LOCATION//</p>
        <p>Home directory: //DIRECTORY//</p>
        <p>Backup: //BACKUP INTERVAL//</p>
    </div> -->
</section>
