<div class="loginSection" style="height: 20%; background-image: linear-gradient(145deg, #0068b7, #003156);">
  <div id="loginHeader">
    <h1>Runbox 7</h1>
    <img src="assets/runbox7_negative_white.png" id="logo" alt="Runbox 7" />
    <h4 id="loginHeaderSubTitle">The fastest webmail app on the planet</h4>
  </div>
</div>
<div class="loginSection" style="width: 100%; height: 60%;">
  <div class="loginSide">
    <img src="assets/runbox7_blue_dark_7only.png" class="side7" style="position: absolute; top: 40vh; left: 7vw; height: 4vh; width: auto; transform: rotate(65deg); filter: opacity(100%)" />
    <img src="assets/runbox7_blue_dark_7only.png" class="side7" style="position: absolute; top: 46vh; left: 12vw; height: 6vh; width: auto; transform: rotate(51deg); filter: opacity(100%)" />
    <img src="assets/runbox7_blue_dark_7only.png" class="side7" style="position: absolute; top: 50vh; left: 18vw; height: 8vh; width: auto; transform: rotate(43deg); filter: opacity(100%)" />
    <img src="assets/runbox7_blue_dark_7only.png" class="side7" style="position: absolute; top: 53vh; left: 27vw; height: 10vh; width: auto; transform: rotate(33deg); filter: opacity(100%)" />
    <img src="assets/runbox7_blue_dark_7only.png" class="side7" style="position: absolute; top: 52vh; left: 64vw; height: 12vh; width: auto; transform: rotate(12deg); filter: opacity(100%)" />
    <img src="assets/runbox7_blue_dark_7only.png" class="side7" style="position: absolute; top: 45vh; left: 74vw; height: 16vh; width: auto; transform: rotate(5deg); filter: opacity(100%)" />
    <img src="assets/runbox7_blue_dark_7only.png" class="side7" style="position: absolute; top: 35vh; left: 85vw; height: 20vh; width: auto; filter: opacity(100%)" />
  </div>
  
  <form *ngIf="!twofactor" (ngSubmit)="onSubmit(loginform.value)" #loginform="ngForm">
    <div class="loginScreen">
      <div id="loginArea">
	<div>
          <img src="assets/runbox7_blue_dark.png" id="logoLogin" alt="Runbox 7" />
	  <p>Log in below or <a href="/signup?runbox7=1" class="login-link">create a new account</a>.</p>
	</div>
	<mat-form-field>
          <input matInput placeholder="Username" name="username" type="email" inputmode="email" autofocus ngModel />
	</mat-form-field>
	<mat-form-field>
          <input matInput placeholder="Password" type="password" name="password" ngModel />
  </mat-form-field>
  <div *ngIf='login_error_html' [innerHTML]="login_error_html" class='login_error_html'></div>
	<button mat-raised-button color="primary" type="submit" id="loginButton">Log in</button>
	<p *ngIf='accountSuspended' class='login_error_html'>
          Your Runbox account has been suspended. <br>
          Please contact <a href="https://support.runbox.com">Runbox Support</a>.
	</p>
	<p *ngIf='accountExpiredTrial' class='login_error_html'>
          Your Runbox trial account has expired. <br>
          Go to <a routerLink="/account/plans">Plans & Upgrades</a> to reopen it now!
	</p>
	<p *ngIf='accountExpiredSubscription' class='login_error_html'>
          Your Runbox subscription has expired. <br>
          Go to <a routerLink="/account/subscriptions">Your Subscriptions</a> to renew it now!
	</p>
	<p *ngIf='accountCanceled' class='login_error_html'>
          Your Runbox subscription has been canceled. <br>
          Please contact <a href="https://support.runbox.com">Runbox Support</a>.
	</p>
	<p *ngIf='accountClosed' class='login_error_html'>
          This Runbox account has been closed. <br>
	</p>
	<p *ngIf='accountError' class='login_error_html'>
          An unknown error has occurred. <br>
	  Please contact <a href="https://support.runbox.com">Runbox Support</a>.
	</p>
	<div id="loginOptions">
	  <mat-checkbox name="is_keep_logged" class="login-checkbox" title="Stay logged into Runbox until logging out manually" ngModel>Stay logged in</mat-checkbox><br />
	  <mat-checkbox name="is_use_rmm6" id="useRmm6" class="login-checkbox" title="Use Runbox 6, the previous version of Runbox" ngModel>Use Runbox 6</mat-checkbox>
	</div>
	<div id='forgotPassword' class="login-link">
	  <a href="/pw_reset">Forgot password?</a>
	</div>
	<div id="progressBar">
	  <mat-progress-bar mode="indeterminate" *ngIf="progressService.httpRequestInProgress | async"></mat-progress-bar>
	</div>
      </div>
    </div>   
  </form>
  <form *ngIf="twofactor" (ngSubmit)="onTwoFactorSubmit(twofactorform.value)" #twofactorform="ngForm">
    <div class="loginScreen">
      <div id="tfaArea">
	<mat-card style="max-width: 90vw">
	  <div>
	    <img src="assets/runbox7_blue_dark.png" id="logoLogin" alt="Runbox 7" />
	  </div>            
		<div *ngIf='login_error_html' [innerHTML]="login_error_html" class='login_error_html'></div>
	  <mat-card-title><h3>Two-Factor Authentication</h3></mat-card-title>
	  <mat-card-content>
            <ng-container *ngIf="!twofactormethodgroup.value">
	      <p>This account has Two-Factor Authentication enabled.</p>
              <p>Please select an option below to continue.</p>
            </ng-container>
            <ng-container *ngIf="twofactormethodgroup.value==='totp'">                            
              <p>Please enter your TOTP code.</p>
              <br />
              <mat-form-field>
		<input matInput placeholder="Timed one-time password" name="totp" id="totp" inputmode="numeric" ngModel />
              </mat-form-field>
	      <button mat-raised-button color="primary" type="submit">Log in</button>
	      <br />
	      <input id='trust_this_browser_totp' name='trust_this_browser_totp' type='checkbox' ngModel>Trust this browser <a href="https://help.runbox.com/login">?</a>
            </ng-container>
            <ng-container *ngIf="twofactormethodgroup.value==='otp'">
              <p>Please enter your OTP code.</p>
              <br />
              <mat-form-field>
		<input matInput placeholder="One-time password" name="otp" id="otp" ngModel />
              </mat-form-field>
	      <button mat-raised-button color="primary" type="submit">Log in</button>
	      <br />
              <input id='trust_this_browser_otp' name='trust_this_browser_otp' type='checkbox' ngModel>Trust this browser <a href="https://help.runbox.com/login">?</a>
            </ng-container>                    
            <ng-container *ngIf="twofactormethodgroup.value==='unlock_code'">
              <p>Please enter your unlock code.</p>
              <br />
              <mat-form-field style="width: 100%">
		<input matInput type="password" placeholder="Unlock code" name="unlock_code" ngModel />
              </mat-form-field>
              <button mat-raised-button color="primary" type="submit">Unlock</button>
            </ng-container>
            <mat-progress-bar mode="indeterminate" *ngIf="progressService.httpRequestInProgress | async"></mat-progress-bar>
	  </mat-card-content>
	  <mat-card-actions style="text-align: center; margin: 0;">
	    <div>
              <mat-button-toggle-group name="twofactormethod" #twofactormethodgroup="matButtonToggleGroup" ngModel>
		<mat-button-toggle value="totp" title="Timed one-time password">
		  <img style="margin: 5px" src="/_img/icon/totp.png" /> TOTP
		</mat-button-toggle>
		<mat-button-toggle value="otp" title="One-time password">
		  <img style="margin: 5px" src="/_img/icon/otp.png" /> OTP
		</mat-button-toggle>   
		<mat-button-toggle value="unlock_code" title="Use unlock code">
		  <img style="margin: 5px" src="/_img/icon/unlock_code.png"> Unlock
		</mat-button-toggle>                         
              </mat-button-toggle-group>
	    </div>
	    <br />
	    <div>
              <button mat-raised-button title="Go back" (click)="twofactor=false">
		<img style="margin: 5px" src="/_img/icon/return.png" /> Go back                                                
              </button>
	    </div>
	  </mat-card-actions>
  </mat-card>
      </div>
    </div>
  </form>
</div>
<div class="loginSection" style="height: 20%; background-image: linear-gradient(170deg, #014f89, #001e35);">
  <div id="loginFooter">
    <p style="margin: 1vh 0 0 0; text-align: center"><a href="https://runbox.com/features/email-services/runbox-7-project/">More information about the Runbox 7 project</a></p>
    <div id="loginFooterMenuBottom">
      <ul>
	<li><a href="/about/">About Runbox</a></li>
	<li><a href="/about/terms-service/">Terms of Service</a></li>
	<li><a href="/about/privacy-policy/">Privacy Policy</a></li>
	<li><a href="https://community.runbox.com/">Community</a></li>
	<!--<li><a href="https://help.runbox.com/">Help & Documentation</a></li>-->
      </ul> 
    </div>
  </div>
</div>
