<style>
    .footerColumn {
        position: absolute;
        bottom: 0px;
        user-select: none;
        -webkit-user-select: none;
        color: this.textColor;                        
        white-space: nowrap;
        height: 0;
        padding: 0;            
        text-align: right;
        font-weight: bold;   
    }
    mat-radio-group {
        display: flex;
        flex-direction: column;
    }
    mat-radio-button {
        margin: 5px;
    }
</style>
<div #tablecontainer style="
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;                    
      overflow-x: auto;
      overflow-y: hidden; 
      user-select: none; 
      -webkit-user-select: none;"
      (scroll)="horizScroll($event)"      
      >        
    <ng-container>
      <ng-container *ngFor="let col of canvastable.columns; let colIndex = index">
        <ng-container *ngIf="col.checkbox">
        <button mat-icon-button style="margin-top: -8px"
                (click)="canvastable.selectRows()"
                (mousedown)="mouseOverSelectAll()"
                (mouseup)="mouseLeftSelectAll()"
                >
            <mat-icon matTooltipPosition="left" matTooltip="Check visible/all messages. Long press for options." matTooltipClass="multiline-tooltip" svgIcon="select-all"></mat-icon>
        </button>
        <span [matMenuTriggerFor]="selectMenu"></span>
        <mat-menu #selectMenu="matMenu">
          <mat-radio-group [(ngModel)]="canvastable.selectWhichRows">
            <mat-radio-button [value]="RowSelect.Visible">Visible only</mat-radio-button>
            <mat-radio-button [value]="RowSelect.All">All rows</mat-radio-button>
          </mat-radio-group>
        </mat-menu>
        </ng-container>
        <div
            *ngIf="!col.checkbox"
            (touchstart)="colresizestart($event.targetTouches[0].clientX,colIndex)"
            (mousedown)="colresizestart($event.clientX,colIndex)"            
            (touchmove)="$event.preventDefault();colresize($event.targetTouches[0].clientX)"
            (touchend)="colresizeend()"            
            (click)="toggleSort(col.sortColumn)"
          style="
              display: flex;
              position: absolute;
              top: 0px;
              user-select: none;
              -webkit-user-select: none;
              color: this.textColor;          
              white-space: nowrap;
              height: 25px;
              padding-top: 5px;
              padding-left: 10px;
              text-align: left;
              overflow: hidden;
            "
            
            [style.width]="col.width+'px'"
            [style.backgroundColor]="col.backgroundColor ? col.backgroundColor : inherit"
            [style.left]="sumWidthsBefore(colIndex)+'px'"
            [style.cursor]="col.sortColumn!==null ? 'pointer' : 'default'"
            >
            {{col.name}}
            <mat-icon class="sortIcon" *ngIf="sortColumn===col.sortColumn" svgIcon="{{sortDescending ? 'chevron-up' : 'chevron-down'}}">
            </mat-icon>
        </div>
      </ng-container>
    </ng-container>
    <div *ngIf="canvastable.rowWrapMode && canvastable.hasSortColumns" style="display: flex">  
      <ng-container *ngFor="let col of canvastable.columns; let colIndex = index">
        <div *ngIf="col.sortColumn!==null"
            (click)="toggleSort(col.sortColumn)"
            (touchstart)="$event.preventDefault();toggleSort(col.sortColumn)" style="   
              padding: 4px;                          
              margin-right: 5px;
              border-radius: 5px;
              user-select: none;
              -webkit-user-select: none;
            "
            [style.backgroundColor]="sortColumn!==col.sortColumn ? 'inherit' : this.textColor"
            [style.fontWeight]="sortColumn===col.sortColumn ? 'bold': 'normal'"
            [style.cursor]="col.sortColumn!==null ? 'pointer' : 'default'"
            >
            <mat-icon *ngIf="sortColumn===col.sortColumn" style="font-size: 12px;" svgIcon="{{sortDescending ? 'chrevron-up' : 'chevron-down'}}"></mat-icon>{{col.name}}          
        </div>   
      </ng-container>
    </div>
    
    <div #tablebodycontainer style="position: absolute;           
      bottom: 0px; 
      right: 0px;
      width: 100%;
      overflow: hidden;
      " 
        [style.left.px]="canvastable.horizScroll"
        [style.top.px]="canvastable.rowWrapMode && !canvastable.hasSortColumns ? 0 : 25"
        >          
      <canvastable [selectListener]="canvastableselectlistener"
        (touchscroll)="handleTouchScroll($event)"
        (columnresizestart)="colresizestart($event.clientx, $event.colindex)"
        (columnresizeend)="colresizeend()"
        (columnresize)="colresize($event)"
        [columnWidths]="columnWidths"
        >
      </canvastable>
    </div>
    <div [hidden]="canvastable.rowWrapMode" *ngFor="let col of canvastable.columns; let colIndex = index"          
        (touchstart)="colresizestart($event.targetTouches[0].clientX,colIndex)"
        (mousedown)="colresizestart($event.clientX,colIndex)"            
        (touchmove)="$event.preventDefault();colresize($event.targetTouches[0].clientX)"
        (touchend)="colresizeend()"            
        (click)="toggleSort(col.sortColumn)"
        class="footerColumn"
              
        [style.width]="columnWidths[col.name]+'px'"
        [style.backgroundColor]="col.backgroundColor ? col.backgroundColor : inherit"
        [style.left]="(sumWidthsBefore(colIndex))+'px'"
        
        >            
        <span style="padding-right: 10px">{{col.footerText}}</span>
    </div>
</div>    
