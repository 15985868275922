<mat-sidenav-container autosize>
    <mat-sidenav #sidemenu
        [opened]="sideMenuOpened"
        [mode]="mobileQuery.matches ? 'over' : 'side'"
        [fixedInViewport]="mobileQuery.matches"
        fixedTopGap="0"
        id="sideMenu"
    >
        <mat-nav-list dense>
            <app-sidenav-menu (closeMenu)="sideMenu.close()"></app-sidenav-menu>

            <ng-content select="nav"></ng-content>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
