<mat-sidenav-container autosize>
    <mat-sidenav #sidemenu
        [opened]="sideMenuOpened"
        [mode]="mobileQuery.matches ? 'over' : 'side'"
        [fixedInViewport]="mobileQuery.matches"
        fixedTopGap="0"
        id="sideMenu"
    >
        <mat-nav-list dense>
            <app-sidenav-menu (closeMenu)="sideMenu.close()"></app-sidenav-menu>
            <a mat-list-item> What do we put here? </a>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar>
            <button mat-icon-button (click)="sidemenu.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
        </mat-toolbar>

        <app-start></app-start>
    </mat-sidenav-content>
</mat-sidenav-container>
