<section class="mat-typography">
    <app-runbox-section>
        <div runbox-section-header>
            <h1 class="runbox-section-header">Last Logins</h1>
        </div>
        <div runbox-section-content class="runbox-section-content" id="last-logins">
            <div class="logins-form">
                <mat-form-field>
                    <mat-label>Service</mat-label>
                    <mat-select name="acl_service" [(ngModel)]="acl_service">
                        <mat-option value="" selected>Any</mat-option>
                        <mat-option *ngFor="let service of rmm.account_security.service.services_translation | keyvalue"
                            [value]="service.key">
                            {{ rmm.account_security.service.services_translation[service.key].name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Status</mat-label>
                    <mat-select name="acl_status" [(ngModel)]="acl_status">
                        <mat-option value="" selected>Any</mat-option>
                        <mat-option value="1">Success</mat-option>
                        <mat-option value="0">Failure</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Period</mat-label>
                    <mat-select name="acl_period" [(ngModel)]="acl_period">
                        <mat-option value="1h" selected>1 hour</mat-option>
                        <mat-option value="1d">1 day</mat-option>
                        <mat-option value="7d">1 week</mat-option>
                        <mat-option value="1m">1 month</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Filter IPs</mat-label>
                    <mat-select name="acl_filter_ips" [(ngModel)]="acl_filter_ips">
                        <mat-option value="">None</mat-option>
                        <mat-option value="not in" selected>Exclude IPs</mat-option>
                        <mat-option value="in">Include IPs</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <input matInput name="acl_ip" placeholder="Enter IP or select from list" [(ngModel)]="acl_ip"
                        (ngModelChange)="acl_ip_changed()">
                </mat-form-field>
                <div class="action-buttons">
                    <button mat-raised-button class="primaryContentButton" (click)="acl_clear()"
                        [disabled]="!is_acl_clear_enabled">Clear</button>
                    <button mat-raised-button class="primaryContentButton" (click)="acl_update()"
                        [disabled]="is_busy_list_logins">Update</button>
                </div>
            </div>
            <!-- header -->
            <div class="grid-table" *ngIf="rmm.account_security.acl.results_logins_list">
                <mat-grid-list cols="6" rowHeight="50px">
                    <mat-grid-tile>
                        <h4>Service</h4>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <h4>Login</h4>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <h4>IP</h4>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <h4>Hostname</h4>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <h4>Time</h4>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <h4>Status</h4>
                    </mat-grid-tile>
                </mat-grid-list>
                <div style='max-height: 500px;'>
                    <div *ngIf="is_busy_list_logins" style="display: flex; justify-content: center">
                        <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
                    </div>
                    <div *ngFor="let result of rmm.account_security.acl.results_logins_list">
                        <mat-grid-list class="grid-results" cols="6" rowHeight="30px">
                            <mat-grid-tile>
                                {{ rmm.account_security.service.services_translation[result.service].name }}
                            </mat-grid-tile>
                            <mat-grid-tile>
                                {{ result.login }}
                            </mat-grid-tile>
                            <mat-grid-tile>
                                {{ result.ip }}
                            </mat-grid-tile>
                            <mat-grid-tile>
                                {{ result.hostname || '-' }}
                            </mat-grid-tile>
                            <mat-grid-tile>
                                {{ result.created }}
                            </mat-grid-tile>
                            <mat-grid-tile>
                                {{ result.success === 1 ? 'success' : 'fail' }}
                            </mat-grid-tile>
                            <!-- <mat-grid-tile>
                        <span *ngIf="result.success === 0"><button mat-raised-button class="primaryContentButton" (click)="ip_always_block(result)">Always block</button></span>
                        </mat-grid-tile> -->
                        </mat-grid-list>
                    </div>
                </div>
            </div>

            <div *ngIf="rmm.account_security.acl.results_logins_list">
                <div class="table-mobile" *ngFor="let result of rmm.account_security.acl.results_logins_list">
                    <div class="table-mobile-row">
                        <span class="row-title">Service:</span>
                        <span>{{ rmm.account_security.service.services_translation[result.service].name }}</span>
                    </div>
                    <div class="table-mobile-row">
                        <span class="row-title">Login:</span>
                        <span>{{ result.login }}</span>
                    </div>
                    <div class="table-mobile-row">
                        <span class="row-title">IP:</span>
                        <span>{{ result.ip }}</span>
                    </div>
                    <div class="table-mobile-row">
                        <span class="row-title">Hostname:</span>
                        <span>{{ result.hostname || '-' }}</span>
                    </div>
                    <div class="table-mobile-row">
                        <span class="row-title">Time:</span>
                        <span>{{ result.created }}</span>
                    </div>
                    <div class="table-mobile-row">
                        <span class="row-title">Status:</span>
                        <span>{{ result.success === 1 ? 'success' : 'fail' }}</span>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </div>
        </div>
    </app-runbox-section>
</section>
