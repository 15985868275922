<section class="mat-typography">
    <app-runbox-section>
        <div runbox-section-header>
            <h1 class="runbox-section-header">Account Password</h1>
        </div>
        <div runbox-section-content class="runbox-section-content">
            <p>Here you can change your account password.</p>
            <p>Your password must be 8-64 characters long and be a mix of characters, numbers, and special characters.</p>
	    <p>If you use an email client (Outlook, Apple Mail, Thunderbird, etc), it may take a few minutes for the new password to take effect.</p>
	    <p><a href="http://help.runbox.com/usernames-and-passwords/" target="rmm6">More information on selecting a password</a>.</p><br>

            <form>
                <mat-form-field class="full-width">
                    <mat-label>Current password</mat-label>
                    <input matInput type="password" name="password" [(value)]="old_password" [(ngModel)]="old_password" (ngModelChange)="check_password()">
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>New password</mat-label>
                    <input matInput type="password" name="password" [(value)]="new_password" [(ngModel)]="new_password" (ngModelChange)="check_password()">
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>Confirm new password</mat-label>
                    <input matInput type="password" name="password" [(value)]="confirm_password" [(ngModel)]="confirm_password" (ngModelChange)="check_password()">
                </mat-form-field>

                <p class="password-error">
                    <span *ngIf="error">{{error}}</span>
                </p>

                <button mat-raised-button class="primaryContentButton" (click)="update_password()" style="margin: 0 10px !important;">Save</button>
            </form>
        </div>
    </app-runbox-section>
</section>
