<mat-toolbar style="background-image: linear-gradient(177deg, #0068b7, #003156);" color="primary" id="mainMenuContainer">
  <a routerLink="/welcome" class="mainMenuLogo"><img src="assets/runbox7_blue.png" id="logo" alt="Runbox 7" /></a>

  <div class="mainMenu">
    <a mat-button routerLink="/">
      <mat-icon svgIcon="email"></mat-icon>
      <p class="mainMenuDesc">Mail</p>
    </a>
  </div>
  <div class="mainMenu">
    <a mat-button routerLink="/contacts">
      <mat-icon svgIcon="account-multiple"></mat-icon>
      <p class="mainMenuDesc">Contacts</p>
    </a>
  </div>
  <div class="mainMenu">
    <a mat-button routerLink="/calendar">
      <mat-icon svgIcon="calendar-range"></mat-icon>
      <p class="mainMenuDesc">Calendar</p>
    </a>
  </div>
  <div class="mainMenu">
    <a mat-button href="/files" target="rmm6">
      <mat-icon svgIcon="folder-outline"></mat-icon>
      <p class="mainMenuDesc">Files</p>
    </a>
  </div>

  <div class="mainMenu">
    <a mat-button routerLink="/account">
      <mat-icon svgIcon="cog"></mat-icon>
      <p class="mainMenuDesc">Settings</p>
    </a>
  </div>
  
  <span flex style="flex-grow: 1"></span>

  <div *ngIf="user_is_trial" class="mainMenu" id="subscribe">
    <a mat-button routerLink="/account/upgrades">
      <mat-icon svgIcon="wallet-membership"></mat-icon> &nbsp;Subscribe Now
      <p class="mainMenuDesc">Full money back guarantee</p>
    </a>
  </div>

  <span flex style="flex-grow: 1"></span>

  <div class="mainMenu">
    <a mat-button routerLink="/help">
      <mat-icon svgIcon="help-circle"></mat-icon>
      <p class="mainMenuDesc">Help</p>
    </a>
  </div>

  <div class="mainMenu">
    <a mat-button href="https://blog.runbox.com/">
      <mat-icon svgIcon="comment-text"></mat-icon>
      <p class="mainMenuDesc">Blog</p>
    </a>
  </div>

  <div class="mainMenu">
    <a mat-button href="/mail/account">
      <mat-icon svgIcon="account"></mat-icon>
      <p class="mainMenuDesc">Account</p>
    </a>
  </div>

  <div *ngIf="user_is_trial" class="mainMenu">
    <a mat-button routerLink="/account/plans">
      <mat-icon svgIcon="wallet-membership"></mat-icon>
      <p class="mainMenuDesc">Subscribe</p>
    </a>
  </div>

  <div *ngIf="!user_is_trial && isMainAccount" class="mainMenu">
    <a mat-button routerLink="/account/plans">
      <mat-icon svgIcon="rocket-launch"></mat-icon>
      <p class="mainMenuDesc">Upgrades</p>
    </a>
  </div>

  <div class="mainMenu">
    <a mat-button (click)="logoutservice.logout()">
      <mat-icon svgIcon="power"></mat-icon>
      <p class="mainMenuDesc">Log out</p>
    </a>
  </div>
</mat-toolbar>

<style>
#connectionDownIndicator {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 999;
}
</style>

<button mat-fab color="warn" *ngIf="rmmoffline.is_offline"
  id="connectionDownIndicator"
  matTooltip="Runbox API unavailable"
>
    <mat-icon svgIcon="cloud-off-outline"></mat-icon>
</button>
