<br />
<h1 *ngIf="!fromUrl; else urlPurchase"> Your shopping cart </h1>
<ng-template #urlPurchase> <h2> Your purchase </h2> </ng-template>

<div *ngIf="itemsSubject | async; else loading">

<div *ngIf="items.length === 0">
    <p>
        Your shopping cart is currently empty. <mat-icon svgIcon="emoticon-sad-outline"> </mat-icon>
    </p>

    <p>
        How about you treat yourself to an
        <button mat-raised-button routerLink="/account/plans" color="primary">
            <mat-icon svgIcon="hot-tub" color="accent"> </mat-icon> upgrade
        </button>
        or go ahead and
        <button mat-raised-button routerLink="/account/subscriptions"  color="primary">
            <mat-icon svgIcon="autorenew" color="accent"> </mat-icon> renew
        </button>
        some of your products and lock in today's prices?
    </p>
</div>

<table mat-table [dataSource]="items" [ngStyle]="{ width: '100%' }" *ngIf="items.length > 0" multiTemplateDataRows>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Product </th>
        <td mat-cell *matCellDef="let i">
            {{ i.product.name || "Product #" + i.pid }}
            <span *ngIf="i.apid"> (renewal) </span>
        </td>
        <td mat-footer-cell *matFooterCellDef> <strong> Total </strong> </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef> Quantity </th>
        <td mat-cell *matCellDef="let i"> {{ i.quantity }} </td>
    </ng-container>

    <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef> Price </th>
        <td mat-cell *matCellDef="let i"> {{ i.product.price | number:'1.2-2' }} {{ currency }} </td>
    </ng-container>

    <ng-container matColumnDef="total-price">
        <th mat-header-cell *matHeaderCellDef> Total </th>
        <td mat-cell *matCellDef="let i"> {{ i.product.price * i.quantity | number:'1.2-2' }} {{ currency }} </td>
        <td mat-footer-cell *matFooterCellDef [ngStyle]="{ 'text-align': mobileQuery.matches ? 'end' : null }">
            <strong> {{ total | number:'1.2-2' }} {{ currency }} </strong>
        </td>
    </ng-container>

    <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef> Remove </th>
        <td mat-cell *matCellDef="let p" [ngStyle]="{ 'text-align': mobileQuery.matches ? 'end' : null }">
            <button mat-icon-button (click)="remove(p)">
                <mat-icon svgIcon="minus-circle-outline"> </mat-icon>
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <ng-container matColumnDef="item-details">
        <td mat-cell *matCellDef="let i" [attr.colspan]="tableColumns.length">
            <table class="detailsTable">
                <tr> <td> Quantity         </td> <td> {{ i.quantity }} </td> </tr>
                <tr> <td> Price (per unit) </td> <td> {{ i.product.price | number:'1.2-2' }} {{ currency }} </td> </tr>
                <tr> <td> Price (total)    </td> <td> {{ i.product.price * i.quantity | number:'1.2-2' }} {{ currency }} </td> </tr>
            </table>
        </td>
    </ng-container>

    <ng-container matColumnDef="empty">
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns" [ngStyle]="{ 'display': mobileQuery.matches ? 'none' : null }">
    <tr mat-row
        *matRowDef="let row; columns: tableColumns;"
        [ngClass]="{ 'regularRow': mobileQuery.matches }"
    ></tr>
    <tr mat-row
        *matRowDef="let row; columns: ['item-details']"
        class="detailsRow"
        [ngStyle]="{ 'display': mobileQuery.matches ? null : 'none' }"
    ></tr>
    <tr mat-footer-row *matFooterRowDef="mobileQuery.matches ? ['name', 'total-price'] : ['name', 'empty', 'empty', 'total-price', 'empty']">
</table>

<div *ngIf="missingProducts.length > 0" style="text-align: center;">
    <p>Some of the products added to your cart ({{ missingProductsString }}) are not currently available for purchase. <br>
    If the product list above is missing something you wanted to renew, contact Runbox Support at
    <a routerLink="/compose" [queryParams]="{ to: 'support@runbox.com' }">
        support@runbox.com
    </a>.
    </p>
</div>

<div *ngIf="items.length > 0 && orderError === undefined">
    <h2> Payment method </h2>

    <style>
    .paymentMethods {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        gap: 20px;
    }

    .paymentMethods app-payment-method {
        flex: 350px 0 1;
        text-align: center;
    }
    
    .paymentMethods mat-expansion-panel {
        flex: 100% 0 0;
    }

    </style>

    <div class="paymentMethods">
        <app-payment-method
            logo="/_img/pay/stripe-logo.png"
            logo_alt="Stripe logo"
	    disable_payment="{{total <= 0}}"
            (clicked)="initiatePayment('stripe')"
          >
            <h4>
                Pay with a payment card or digital wallet
            </h4>
            <p>
                We support all major payment cards and wallets.
            </p>
        </app-payment-method>

        <app-payment-method
            logo="/_img/pay/bitcoin.png"
            logo_alt="Bitcoin logo"
	    disable_payment="{{total <= 0}}"
            (clicked)="initiatePayment('coinbase')"
        >
            <h4>
                 Pay with cryptocurrencies using Coinbase
            </h4>
            <p>
                Your transaction is secured by cryptography.
            </p>
        </app-payment-method>

        <app-payment-method
          logo="/_img/pay/paypal_bundle_s.png"
          logo_alt="PayPal logo"
	  disable_payment="{{total <= 0}}"
	  (click)="initiatePayment('paypal')"
          >
          <h4>
            Pay with a payment card or PayPal wallet
          </h4>
          <p>
            Note that your subscvriptions will not recur automatically when using PayPal.
          </p>
        </app-payment-method>
	
        <mat-expansion-panel style="margin: 50px 10px; text-align: center;">
          <mat-expansion-panel-header>
            <mat-panel-title style="text-align: center"> Other payment methods </mat-panel-title>
          </mat-expansion-panel-header>
      	  <p>
            <button id="payDirectly" mat-raised-button (click)="initiatePayment('giro')" color="primary">
	            Bank Transfer, or Cash Payment
            </button>
          </p>
          <p>
            You can send us a SWIFT/IBAN bank transfer or mail us an envelope with cash in your preferred currency.
          </p>
          <p>
            Please note that your subscription won't activate until the payment is received by Runbox.
          </p>
      	</mat-expansion-panel>
    </div>
</div>

</div>

<div *ngIf="orderError !== undefined" style="display: flex; flex-flow: column; align-items: center;">
    <h3> There is a problem with your order </h3>

    <div *ngIf="orderError === errors.CANT_LOAD_PRODUCTS">
        Could not load products in your cart. <br>
        Try again later, or contact Runbox Support at
        <a routerLink="/compose" [queryParams]="{ to: 'support@runbox.com' }">
            support@runbox.com
        </a>.
    </div>

    <div *ngIf="orderError === errors.NEED_SUB_FOR_ADDON">
        You need to
        <button mat-button class="contentButton" routerLink="/account/plans">
            purchase a subscription
        </button>
        to be able to use addons.
    </div>
</div>

<ng-template #loading>
    <app-runbox-loading
        *ngIf="orderError === undefined"
        text="Loading your cart details..."
    >
    </app-runbox-loading>
</ng-template>
